<template lang="pug">
.ds-input-element-options
  PaneLayout(gap="4px")
    template(#left)
      .input-label {{ $t('options') }}
    template(#right)
      draggable.d-flex(v-model="draggingOptions" @start="startDrag" @end="endDrag")
        transition-group(name="fade")
          div(
            v-for="(item, index) in selectedElement.data.form.customSettings.options"
            :key="item.value"
          )
            .d-flex.option-input-holder.pb-0(style="align-items: center")
              Drag(v-if="draggingOptions.length > 1")
              OmInput(:id="`option-${index}`" v-model="item.key" @input="update" small)
              transition(name="fade")
                OmButton.ml-2.mb-1(
                  v-if="optionsCount > 1"
                  icon="trash-alt"
                  ghost
                  iconSize="1.5em"
                  iconOnly
                  @click="removeAnotherOption(index)"
                )
      Heading.my-4(v-if="selectedElementType === 'checkbox' && optionsCount === 1" label) {{ $t('privacyPolicyLink') }}
        template(#prependEnding)
          OmSwitch#inputRequired(:value="isPrivacyPolicy" @switch="isPrivacyPolicy = $event")
      OmInput#privacyPolicyUrl.mb-4(
        small
        v-if="isPrivacyPolicy && optionsCount === 1"
        v-model="privacyPolicyUrl"
        placeholder="https://yoursite.com/privacy-and-policy"
        :error="isInvalidLink"
      )
        template(#error v-if="isInvalidLink")
          span {{ $t(validationError.msg) }}
      .d-flex.align-items-center.brand-color.cursor-pointer.mt-4(@click="addAnotherOption")
        AddIcon.mr-2
        .font-size-0--875.font-weight-semibold.line-height-1 {{ $t('addNewOption') }}
</template>
<script>
  import { mapState } from 'vuex';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import itemMixin from '@/editor/mixins/item';
  import Drag from '@/editor/components/svg/Drag.vue';
  import draggable from 'vuedraggable';
  import { nanoid } from 'nanoid';
  import { get as _get } from 'lodash-es';
  import AddIcon from '@/editor/components/svg/Add.vue';
  import Heading from '@/components/Editor/Heading.vue';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: { PaneLayout, Drag, draggable, AddIcon, Heading },
    mixins: [itemMixin],
    computed: {
      ...mapState(['selectedElement', 'validationError']),
      draggingOptions: {
        get() {
          return _clone(this.selectedElement.data.form.customSettings.options);
        },
        set(value) {
          this.setValueOf('data.form.customSettings.options', value);
        },
      },
      isPrivacyPolicy: {
        get() {
          return this.getValueOf('data.isPrivacyPolicy');
        },
        set(value) {
          this.setValueOf('data.isPrivacyPolicy', value);
        },
      },
      privacyPolicyUrl: {
        get() {
          return this.getValueOf('data.privacyPolicyUrl');
        },
        set(value) {
          this.setValueOf('data.privacyPolicyUrl', value);
        },
      },
      optionsCount() {
        const options = this.getValueOf('data.form.customSettings.options', []) || [];
        return options ? options.length : 0;
      },
      isInvalidLink() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.privacyPolicyUrl')
        );
      },
      hasFeedbackQuestion() {
        const question = _get(this.selectedElement, 'data.feedback.question');
        return this.radioOrCheckbox && question && question.length;
      },
      displayType() {
        return _get(this.selectedElement, 'data.displayType');
      },
      selectedElementType() {
        return this.selectedElement.data.form.customSettings.type;
      },
      extraOptionFields() {
        let fields = {};

        if (this.hasFeedbackQuestion) {
          if (this.displayType === 'button') {
            fields = {
              jumpToPage: null,
              action: 'nextPopup',
              redirectUrl: '',
              rawPhoneNumber: '',
              keepQueryParams: false,
              newTab: false,
            };
          } else {
            fields = {
              jumpToPage: null,
            };
          }
        }

        return fields;
      },
    },
    methods: {
      startDrag(params) {
        params.item.style.opacity = 0;
      },
      endDrag(params) {
        params.item.style.opacity = 1;
      },
      addAnotherOption() {
        const newIndexNumber = nanoid(9);
        const len = this.selectedElement.data.form.customSettings.options.length;
        let option = {
          key: this.$t('myOption1', { num: len + 1 }),
          value: `option_${newIndexNumber}`,
        };

        option = Object.assign(option, this.extraOptionFields);

        this.selectedElement.data.form.customSettings.options.push(option);
        this.update();
      },
      removeAnotherOption(index) {
        this.selectedElement.data.form.customSettings.options.splice(index, 1);
        this.update();
      },
      update() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      setOptionFields() {
        this.selectedElement.data.form.customSettings.options.forEach((o, index) => {
          this.selectedElement.data.form.customSettings.options[index] = {
            key: o.key,
            value: o.value,
            ...this.extraOptionFields,
          };
        });
        this.update();
      },
    },
  };
</script>

<style scoped lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .input-label
    color: $om-dark-grey-3
    font-size: 0.75rem
</style>
