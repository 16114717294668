<template lang="pug">
BasePane.ds-picker-pane
  InputElementDropdown.mt-3(
    :items="inputs"
    v-model="inputValue"
    @manage="manageFields"
    @create="createNewInput"
  )
  InputCommonFields
    template(#placeholder)
      InputElementOptions
  hr.mt-3.mb-4
  Accordion(fix)
    template(#title) {{ $t('display') }}
    OmColorInput(
      :label="$t('color')"
      typeOfComponent="input-picker"
      property="desktop.checkedColor"
      layout="col"
      dsAllowed
    )
    .ds-style-block
      .sidebar-label {{ $t('style') }}
      RadioStyleChooser(:type="type")
    template(#moreOrLess)
      Dropdown#smart-size-type(
        :label="$t('width')"
        layout="col"
        v-model="smartSizeType"
        :items="smartSizeTypeOptions"
      )
      template(v-if="isSizeManual")
        .d-flex.justify-space-between
          .sidebar-label {{ $t('breakLines') }}
          OmSwitch#picker-breaklines(:value="breakLines" @switch="breakLines = $event")
        RangeInput(:label="$t('width')" :min="0" :max="600" :step="1" v-model="smartSizeWidth")
      RangeInput(:label="$t('height')" :min="0" :max="600" :step="1" v-model="height")
      Align(:labelOverride="$t('verticalAlignment')" vertical v-model="verticalAlign")
      Align(v-if="isSizeManual" v-model="horizontalAlign" flexOptions)
  hr.mt-1.mb-4
  Font
    template(#colors)
      OmColorInput(
        :label="$t('fontColor')"
        property="desktop.color"
        typeOfComponent="input-picker"
        layout="col"
        dsAllowed
        :editMobile="false"
      )
    template(#styles)
      FontStyle(
        :label="$t('style')"
        :fontItalic.sync="fontItalic"
        :textDecoration.sync="textDecoration"
      )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title) {{ $t('spacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Margin
  hr.my-3
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
    BackgroundAndBorder(hideTitle typeOfComponent="input-picker" fixOptions shadow deviceSelector)
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import InputElementDropdown from '@/components/Editor/InputElementDropdown/InputElementDropdown.vue';
  import InputCommonFields from '@/components/Editor/InputCommonFields/InputCommonFields.vue';
  import InputElementOptions from '@/components/Editor/InputElementOptions/InputElementOptions.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import RadioStyleChooser from '@/editor/components/RadioStyleChooser.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import FontStyle from '@/components/Editor/FontStyle/FontStyle.vue';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import itemMixin from '@/editor/mixins/item';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      BasePane,
      InputElementDropdown,
      InputCommonFields,
      InputElementOptions,
      Accordion,
      RadioStyleChooser,
      Font,
      Margin,
      Hide,
      BackgroundAndBorder,
      Dropdown,
      PaneLayout,
      RangeInput,
      Align,
      FontStyle,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data: () => ({
      inputs: [],
    }),
    computed: {
      selectedElementType() {
        return this.getValueOf('data.form.customSettings.type');
      },
      inputValue: {
        get() {
          const { name = null, customId = null } = this.getValueOf('data.form.customSettings', {});
          return { name, customId };
        },
        set(v) {
          console.log('value', v);
          this.$bus.$emit('switchInputElement', { customField: v });
        },
      },
      type() {
        return this.getValueOf('data.form.customSettings.type', 'radio');
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      smartSizeTypeOptions() {
        const opts = [
          { text: this.$t('manual'), value: 'manual' },
          { text: this.$t('w100'), value: '100w' },
        ];

        if (this.mobilePreview) {
          opts.unshift({ text: this.$t('default'), value: null });
        }

        return opts;
      },
      smartSizeType: {
        get() {
          return this.getValueOf('smartSize.type');
        },
        set(value) {
          this.setValueOf('smartSize.type', value);
        },
      },
      breakLines: {
        get() {
          return this.getValueOf('orientation');
        },
        set(value) {
          this.setValueOf('orientation', value);
        },
      },
      isSizeManual() {
        return this.smartSizeType === 'manual';
      },
      smartSizeWidth: {
        get() {
          return Number(this.getValueOf('smartSize.width'));
        },
        set(value) {
          this.setValueOf('smartSize.width', Number(value));
        },
      },
      height: {
        get() {
          return Number(this.getValueOf('height'));
        },
        set(value) {
          this.setValueOf('height', Number(value));
        },
      },
      verticalAlign: {
        get() {
          return this.getValueOf('desktop.alignPicker');
        },
        set(value) {
          this.setValueOf('desktop.alignPicker', value);
        },
      },
      horizontalAlign: {
        get() {
          return this.getValueOf('justifyContent');
        },
        set(value) {
          this.setValueOf('justifyContent', value);
        },
      },
      fontItalic: {
        get() {
          return this.getValueOf('desktop.fontItalic');
        },
        set(v) {
          this.setValueOf('desktop.fontItalic', v);
        },
      },
      textDecoration: {
        get() {
          return this.getValueOf('desktop.textDecoration');
        },
        set(v) {
          this.setValueOf('desktop.textDecoration', v);
        },
      },
    },
    mounted() {
      this.getInputs();

      // region Manage inputs ON listener
      this.$bus.$on('switchInputElement', this.getInputs);
      // endregion
    },

    beforeDestroy() {
      // region Manage inputs OFF listener
      this.$bus.$off('switchInputElement', this.getInputs);
      // endregion
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      ...mapActions(['getMatchingInputs']),
      async getInputs() {
        this.inputs = await this.getMatchingInputs();
      },
      manageFields() {
        this.changeFormManagerVisibility({ show: 'manageField', fields: this.inputs });
      },
      createNewInput() {
        this.changeFormManagerVisibility({ show: 'secondStep' });
      },
      inputValidation() {
        this.changeFormManagerVisibility({ show: 'inputValidations' });
      },
    },
  };
</script>

<style lang="sass">
  .ds-style-block
    .sidebar-input-wrapper
      padding: 0 !important
</style>
